import React, { useEffect, useState } from 'react';
import Layout from '../../../../components/layout'
import Seo from '../../../../components/seo';
import './styles/main.scss';

const Page = (location) => {
  const classes = [
    {
      id: 1,
      date: new Date('2021-10-07 12:25'),
      video: 'qNio2As6FIo'
    },
    {
      id: 2,
      date: new Date('2021-10-09 20:00'),
      video: 'ba_Ki2c7Pws'
    },
    {
      id: 3,
      date: new Date('2021-10-10 20:00'),
      video: 'KkTn7062Ce4'
    }
  ];

  let [active, setActive] = useState(classes[0]);

  const getStatus = (date) => {
    const difference = (((new Date() - date) / 1000));
    console.log(date, new Date(), difference);
  
    if (difference > 0) {
      return true;
    }
    return false;
  };

  const setClassActive = (item) => {
    if (getStatus(item.date)) {
      setActive(item);
    }
  };

  const trackAnalytics = () => {
  };

  useEffect(() => {
    trackAnalytics();
  }, [])

  return (
    <Layout page="workshop" location={location} name="workshop">
      <Seo
        title={`Workshop 25/10`}
        description="Workshop 25/10."
        url="/workshop/" />

      <section className="workshop">
        <div className="container">
          <div className="row">
            <h2>Aula {active.id}</h2>
            <div className="workshop-class">
              <iframe title="Video" className="video youtube" src={`https://www.youtube.com/embed/${active.video}`} frameborder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture;" allowfullscreen></iframe>
            </div>
          </div>

          <div className="row workshop-classes-wrapper">
            { /* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/no-static-element-interactions */ }
            <div className="workshop-classes" onClick={() => setClassActive(classes[0])}>
              <p className="title">Aula</p>
              <p className="id">{classes[0].id}</p>
              <p className="status">{getStatus(classes[0].date) ? 'Liberada' : 'Bloqueada'}</p>
            </div>

            { /* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/no-static-element-interactions */ }
            <div className="workshop-classes" onClick={() => setClassActive(classes[1])}>
              <p className="title">Aula</p>
              <p className="id">{classes[1].id}</p>
              <p className="status">{getStatus(classes[1].date) ? 'Liberada' : 'Bloqueada'}</p>
            </div>

            { /* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/no-static-element-interactions */ }
            <div className="workshop-classes" onClick={() => setClassActive(classes[2])}>
              <p className="title">Aula</p>
              <p className="id">{classes[2].id}</p>
              <p className="status">{getStatus(classes[2].date) ? 'Liberada' : 'Bloqueada'}</p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Page;
